// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bN_kw";
export var caseStudyBackground__lineColor = "bN_kt";
export var caseStudyHead = "bN_kn";
export var caseStudyHead__imageWrapper = "bN_kp";
export var caseStudyProjectsSection = "bN_kx";
export var caseStudyQuote__bgRing = "bN_kr";
export var caseStudyQuote__bottomVideo = "bN_kR";
export var caseStudyVideoReview = "bN_l7";
export var caseStudyVideoReview__bgRing = "bN_l8";
export var caseStudyVideo__ring = "bN_kG";
export var caseStudy__bgDark = "bN_km";
export var caseStudy__bgLight = "bN_kl";
export var caseStudy__bgLightReverse = "bN_l6";