import { Link } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'

import videoRingsImage from '~/assets/images/case-study/tradervs/video-review-rings.svg?file'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyClientVideoReview from '~/views/CaseStudies/components/CaseStudyClientVideoReview'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './Tradervs.module.scss'
import useTradervsStaticQuery from './useTradervsStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const quotes = {
  client: {
    name: 'Celso Prado',
    position: 'Digital General Manager at the Adventures Group',
  },
  designer: {
    name: 'Inessa',
    position: 'UI/UX Lead at Codica',
  },
  fe: {
    name: 'Eugene',
    position: 'Tech Lead & React expert at Codica',
  },
}

const previewAfricarTitle = 'Multi-Vendor Vehicle Marketplace'
const previewIxTitle = 'Online Travel Marketplace'

const CaseStudiesTradervs = () => {
  const query = useTradervsStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const clientVideoReview = getImage(
    fileToImageLikeData(query.clientVideoReview),
  )
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const solutionDeliveredSeven = getImage(
    fileToImageLikeData(query.solutionDeliveredSeven),
  )
  const solutionDeliveredEight = getImage(
    fileToImageLikeData(query.solutionDeliveredEight),
  )
  const solutionDeliveredNine = getImage(
    fileToImageLikeData(query.solutionDeliveredNine),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudyHead}>
        <CaseStudyHead
          title="Caravan selling website in Australia for TradeRVs"
          subtitle="Learn how Codica helped the customer to get the user-friendly and visually-appealing online marketplace for caravans by redesigning the previous web solution, creating new UI and UX, and integrating the platform with third-party applications."
          maxWidthSubtitle="400px"
          maxWidthInfo="340px"
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAlt="Marketplace for caravans TradeRVs"
          imageTitle="Caravan sales marketplace TradeRVs"
          domain="Recreational vehicles (RVs)"
          location="Australia"
          timeline="August 2020 - February 2021"
          services={
            <>
              <Link to="/services/product-discovery/" key="productKey">
                Product discovery
              </Link>
              ,
              <Link
                key="designKey"
                to="/services/ui-ux-design/"
                className="ml5"
              >
                UX/UI design services
              </Link>
              , Front-end and Back-end development,
              <Link
                to="/services/progressive-web-apps-development/"
                className="ml5"
                key="pwaDevKey"
              >
                PWA development services
              </Link>
              ,
              <Link
                to="/services/online-marketplace-development/"
                className="ml5"
                key="marketplaceDevKey"
              >
                Online marketplace development
              </Link>
            </>
          }
          team="3 Software Developers, 1 UX/UI Designer, 1 QA Engineer, 1 Project Manager, 1 DevOps Engineer"
          technologies="Ruby on Rails, React JS, PostgreSQL, AWS"
        />
      </div>

      <div className={containerStyles.caseStudyVideoReview}>
        <LazyHydrate whenVisible>
          <CaseStudyClientVideoReview
            color="videoReviewTradervs"
            link="https://www.youtube.com/embed/K9aOABDeKPI"
            clientImage={clientVideoReview}
            imageTitle="Customer review about caravan sales marketplace project"
            name="Celso Prado, Digital General Manager at the Adventures Group"
            review={[
              {
                item: '“One of the main projects I took on when accepting this role was to redevelop two of the business’s various websites. The Codica team seemed to have a clear process from the beginning. The initial sales contact was professional, and we got a real feel for the company when we involved some developers in the conversation.',
              },
              {
                item: 'We are in verticals where market leaders have hundreds of in-house developers and multi-billion dollars in annual revenue. And despite all of that, our lean team, with the help from Codica, is growing its market share year after year. They are like my secret weapon.”',
              },
            ]}
            bgRings
            bgRingsClass={containerStyles.caseStudyVideoReview__bgRing}
            bgRingImage={videoRingsImage}
          />
        </LazyHydrate>
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTitleLeftList="Challenges"
        bgTextLeft={[
          'TradeRVs is an online marketplace for campers in Australia, where they can sell or buy new or used caravans, motorhomes, camper trailers, parts and accessories, and other recreational vehicles.',
          <div className="mb-3" key="bgTextLeft" />,
          'Moreover, on the platform users can find the latest news, articles, and advice from the specialists and fans of the recreational vehicles industry.',
        ]}
        bgTextRight="Our goal was to redesign the caravan selling website and create a new website to help our client further develop their business, increase the distribution and visibility of advertisements, and attract more leads."
        sectionBusiness={[
          {
            label:
              'Redesign the existing solution and create a new platform to help our client further develop their online caravan store.',
          },
          {
            label:
              'Build a PWA to make the online marketplace for campers mobile-friendly.',
          },
          {
            label:
              'Generate more leads to advertisers, by increasing the number of private listings submissions, and attract dealers to place their offers.',
          },
          {
            label:
              'Provide third-party integrations with service content providers and dealer management systems.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Built a Progressive Web App, providing enhanced user experience and made the caravan selling website in Australia mobile-friendly, which affects the website’s conversion rate.',
          },
          {
            label:
              'Established valid synchronization of the data between the marketplace for caravans and the CDS platform to connect multiple dealers CMS/CRM management systems throughout Australia.',
          },
          {
            label:
              'Customized the function of choosing different advertising packages to help private sellers and dealers increase sales by promoting their listings.',
          },
          {
            label:
              'Redesigned the online marketplace for campers to make it user-friendly and intuitive. This step helped our client increase user engagement on the website.',
          },
        ]}
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“A quality web design is a design that most effectively solves business problems. Our team worked thoroughly on TraveRVs and Trade A Boat projects, using the latest design techniques to create eye-catching, modern, and user-friendly interfaces.',
            },
            {
              text: 'These are both complex and significant projects. We created new websites which ensure efficient online presence for our client’s businesses. I am very happy to be working as part of the talented and professional team to keep TradeRVs and Trade A Boat projects on their successful course.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/Inessa.png"
              alt={`${quotes.designer.name}, ${quotes.designer.position} at Codica`}
              title={`${quotes.designer.name}, ${quotes.designer.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.designer.name}
          position={quotes.designer.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need a reliable team to build a custom web solution?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          isImageSmall
          image={solutionDeliveredOne}
          title="Mobile-friendly platform"
          description={[
            'Progressive Web Application (PWA) is a modern technology that allows users to download a website that works as an application on mobile devices.',
            <div className="mb-2" key="descr" />,
            'We built a cross-platform progressive web application, thanks to which the online marketplace for RVs and caravans can remain available with any mobile device and operating system.',
          ]}
          alt="PWA for TradeRVs online marketplace"
          imageTitle="PWA for online marketplace for RVs"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="SEO for higher website rankings"
          description={[
            'Due to the quality search engine optimization we conducted, the rankings of the online marketplace platform for caravans have become higher.',
            <div className="mb-2" key="descrOne" />,
            'We have improved the Core Web Vitals indicators, which also are the ranking factor, and make for a better user experience.',
            <div className="mb-2" key="descrTwo" />,
            'We also added micro-markup data to the web pages so that the search bot better indexes the content. Moreover, we provided the transition to the new version of the website, with redirects to the most important pages.',
          ]}
          alt="Core Web Vitals indicators for caravan selling website"
          imageTitle="TradeRVs marketplace optimization"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Third-party services integration"
          description={[
            'Thanks to the integration with third-party services such as the CDS platform, the TradeRVs can get listings from dealers all over the continent.',
            <div className="mb-2" key="descr" />,
            'This improvement was essential for the custom marketplace development because now the platform has expanded the number of advertisements from dealers.',
          ]}
          alt="Third-party integrations for TradeRVs caravan marketplace"
          imageTitle="Third-parties for caravan selling website TradeRVs"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Smart search"
          description={[
            'By optimizing search filters and making them more structured, we have provided a better user and customer experience to the website’s visitors.',
            <div className="mb-2" key="descr" />,
            'Thanks to this improvement, users can quickly find information they’re searching for.',
          ]}
          alt="Convenient search for TraderVs website"
          imageTitle="Improved search for caravan selling website"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="User-friendly product card"
          description="We made a product card understandable and convenient for users. Users find out all the product characteristics from the card: from the model and price to reviews. Moreover, we created an icon for each category on the site to enhance the user experience."
          alt="Product card for TradeRVs website"
          imageTitle="Product card for caravan selling website"
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Simple listing creation"
          description={[
            'Our team divided into steps the process of adding product listings to the site. The aim was to simplify the process of ads creation. Now, a seller can create an ad in five simple steps.',
            <div className="mb-2" key="descr" />,
            'To make a listing, sellers should choose a category of the product, fill in all details about the vehicle, and add photos. After that, add a video and additional features. Eventually, fill in their contact information.',
          ]}
          alt="Ads creation for TradeRVs"
          imageTitle="Simple listings creation for TradeRVs"
        />
      </div>

      <div className={containerStyles.caseStudy__bgLightReverse}>
        <CaseStudySolution
          image={solutionDeliveredSeven}
          title="Promotional offers for users"
          description={[
            'We customized the marketplace for caravans so that private sellers and dealers have an option to promote their products, by choosing one of the available packages when creating a listing.',
            <div className="mb-2" key="descr" />,
            'Each package contains special services, such as unlimited listings and updates, placing listings higher in search results or publishing them in one of the caravan industry’s leading magazines, TradeRVs.',
          ]}
          alt="Promotional packages for TradeRVs caravan selling website"
          imageTitle="Promotional packages for dealers and private sellers"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredEight}
          title="Easy-to-use dashboard"
          description={[
            'Codica’s experts developed a clean and modern dashboard to demonstrate listing performance to advertisers. With its help, sellers will easily collect data about their ads, such as inquiries from users, views, and sales numbers.',
            <div className="mb-2" key="descr" />,
            'Moreover, we offered an opportunity for dealers to subscribe to the newsletter and get monthly statistics about their listings.',
          ]}
          alt="Dashboard for caravan selling website"
          imageTitle="Clean dashboard for online marketplace for campers"
        />
        <CaseStudySolution
          image={solutionDeliveredNine}
          title="UI redesign"
          description={
            <>
              We analyzed the existing solution and listed the aspects that
              should be upgraded. Then, we delivered
              <Link
                to="/services/ui-ux-design/website-redesign-services/"
                style={{ color: '#e4282f' }}
                className="ml5"
                key="keyCaseSolutionRedesignKey"
              >
                website redesign services
              </Link>
              , for the TradeRVs according to the company’s identity.
              <div className="mb-2" key="descr" />
              We developed the new look of the website, from the design of
              blocks to fonts, added quality content and illustrations. In such
              a way, we have improved user engagement, which is vital for
              marketplace development.
            </>
          }
          alt="UI redesign of the online marketplace for campers"
          imageTitle="UI redesign of TradeRVs online marketplace"
        />
      </div>

      <LazyHydrate whenVisible>
        <CaseStudyVideoCase
          title="How it works"
          description="The video below explains how the caravan selling website works. You can see the process of buying and selling recreational vehicles and what additional functionality the platform provides for users."
          ringsClass={containerStyles.caseStudyVideo__ring}
          image={videoCaseImage}
          videoLink="https://www.youtube.com/embed/CLNGG2pUWCk"
        />
      </LazyHydrate>

      <div className={containerStyles.caseStudyQuote__bottomVideo}>
        <CaseStudyQuote
          quote={[
            {
              text: '“When working on this project, I got an interesting task to implement two websites, Trade A Boat and TradeRVs, similar in design and functionality but different in content in one repository. To fulfill this objective, we created a mechanism that allowed easily adding and changing content depending on the environment variable.',
            },
            {
              text: 'It was also an exciting task to implement these websites from scratch and at the same time maintain SEO optimization of existing web solutions. In general, the project gave me vast experience in implementing complex non-trivial tasks, SEO optimization, and application security.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/FE-Eugene.png"
              alt={`${quotes.fe.name}, ${quotes.fe.position} at Codica`}
              title={`${quotes.fe.name}, ${quotes.fe.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.fe.name}
          position={quotes.fe.position}
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudyTech
          technologies={[
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'AWS', icon: 'aws' },
            { label: 'React', icon: 'react' },
            { label: 'Next.js', icon: 'nextjs' },
          ]}
          integrations={[
            { label: 'Stripe', icon: 'stripe' },
            { label: 'CDS', icon: 'cds' },
            { label: 'HemaX', icon: 'hemax' },
            { label: 'Gitlab', icon: 'gitlab' },
            { label: 'Sentry', icon: 'sentry' },
            { label: 'HubSpot', icon: 'hubspot' },
          ]}
        />
      </div>

      <CaseStudyQuote
        isBottom
        quote={[
          {
            text: '“During the work on the project, Codica’s experts showed great communication skills and a high level of professionalism. They performed the work with high quality, taking into account all our requirements, and delivered the solution within the agreed time frame.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/clients/client-tradervs.png"
            alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
            title={`${quotes.client.name}, ${quotes.client.position}`}
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.client.name}
        position={quotes.client.position}
      />

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s turn your idea into a successful product!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewAfricarTitle}
              color="africarColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/africar.png"
                  alt={`Case study: ${previewAfricarTitle} | Codica`}
                  title={`Case study: ${previewAfricarTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="multi-vendor-vehicle-marketplace"
            />
            <CaseStudyPreview
              title={previewIxTitle}
              color="ixColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/iexpedition.png"
                  alt={`Case study: ${previewIxTitle} | Codica`}
                  title={`Case study: ${previewIxTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="travel-management-platform"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesTradervs
